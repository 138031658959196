import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

export function initSentry() {
    if (location.host.indexOf('m.fwlxt.com') != -1) {
        Sentry.init({
            dsn: 'https://5c2d42e694584f65a5d85f28dec801b8@sentry.scimall.org.cn/2',
            integrations: [
                new Integrations.BrowserTracing()
            ],
            tracesSampleRate: 1.0
        });
    }
}